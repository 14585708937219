<template>
    <v-form class="mt-15" ref="billingBilletForm" v-model="valid">
        <v-row>
            <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
                <v-subheader class="pl-0 mb-5">
                    <h3>Selecione fechamento e vencimento</h3>
                </v-subheader>
                <v-text-field class="requiredField" :rules="$store.state.formRules.required" v-model="formData.closed_date"
                    type="number" clearable label="Dia do fechamento"></v-text-field>
                <v-text-field class="requiredField" :rules="$store.state.formRules.required" v-model="formData.due_date"
                    type="number" clearable label="Dia do vencimento"></v-text-field>
                <!-- <v-menu v-model="due_dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field class="requiredField" :rules="$store.state.formRules.required" clearable
                            v-model="due_dateFormated" label="Data de fechamento" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.due_date" @input="due_dateMenu = false"></v-date-picker>
                </v-menu> -->
                <div class="infoText pt-2">
                    <h6 class="subtitle-2 grey--text text--darken-1">
                        Último dia do mês
                    </h6>
                    <p class="caption mb-2 grey--text text--darken-1">
                        Quando selecionados os dias 30 , ou 31, nos meses que não tiverem, como em fevereiro, será utilizado
                        o
                        útimo dia do mês.
                    </p>

                </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
                <v-subheader class="pl-0 mb-5">
                    <h3>Escolha o formato da cobrança</h3>
                </v-subheader>
                <v-select class="requiredField" :rules="$store.state.formRules.required" clearable item-value="id"
                    item-text="name" :items="billingsType" v-model="formData.billing_type"
                    label="Tipo de cobrança"></v-select>
                <v-text-field-money class="requiredField" v-model="formData.billing_value" v-bind:label="'Valor do boleto'"
                    v-bind:properties="{
                        rules: $store.state.formRules.required,
                        suffix: formData.billing_value && formData.billing_value.length && formData.billing_type == 2 ? '%' : '',
                        prefix:
                            formData.billing_value && formData.billing_value.length && formData.billing_type == 1
                                ? 'R$'
                                : '',
                        readonly: false,
                        outlined: false,
                        clearable: true,
                        placeholder: ' ',
                    }" v-bind:options="{
    locale: 'pt-BR',
    length: 11,
    precision: 2,
    empty: null,
}" />
                <div class="infoText pt-2">
                    <h6 class="subtitle-2 grey--text text--darken-1">
                        Tipo de cobrança
                    </h6>
                    <p class="caption mb-2 grey--text text--darken-1">
                        Você pode escolher um valor fixo para cada boleto gerado, ou, uma porcentagem sobre o valor de cada
                        boleto.
                    </p>

                </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
                <v-subheader class="pl-0 mb-0">
                    <h3>Selecione um perfil de cobrança</h3>
                </v-subheader>
                <v-select @blur="resetValidationField" ref="showHint" no-data-text="Nenhum perfil foi encontrado"
                    hint="<span>Antes de criar um contrato é necessário criar um perfil de cobrança. Para criar um agora <a href='/billing-profile?showProf=1' >clique aqui!</a></span>"
                    :persistent-hint="profilesCbos.length > 0 ? false : true" :rules="$store.state.formRules.required"
                    class="requiredField" clearable v-model="formData.profile_name" :items="profilesCbos" label="Perfil"
                    item-text="name" item-value="name">

                    <template v-slot:message="{ message, key }">
                        <span v-html="message"></span>
                    </template>
                </v-select>
                <v-subheader class="pl-0 mb-0 mt-2">
                    <h3>Geração automática da cobrança</h3>
                </v-subheader>
                <v-checkbox v-model="formData.automatic_generation" label="Gerar cobrança automaticamente"
                    color="primary"></v-checkbox>

            </v-col>
            <!-- <ProfileRegister :banksProfileRegisterCbo="banksProfileRegisterCbo"
                :toogleRegisterProfileDilog="toogleRegisterProfileDilog" :registerProfileDilog="registerProfileDilog" /> -->
        </v-row>
    </v-form>
</template>

<script>
import * as moment from "moment";
const resetBillingBilletForm = {
    profile_name: "",
    closed_date: "",
    // ->integer (dia)
    due_date: "",
    // ->integer (dia)
    billing_type: "",
    // -> 1 ou 2  sendo  1 - valor fixo e 2 para percentual
    billing_value: "",
    // -> float para moeda
    billing_percent: "",
    // -> float para pertual
    automatic_generation: true,
    // -> booleano
}
// import ProfileRegister from "../clients/billets/profiles/ProfileRegister.vue";
export default {
    mounted() {
        if (this.editContractData) {
            this.formData = { ...this.editContractData.payment_condition }
        };
        // this.getConfiguredBanks()
        // this.getProfiles()
    },
    watch: {
        contractDialog(v) {
            //console.log("watch");
            if (!v) {
                this.formData = { ...resetBillingBilletForm };
                this.$refs.billingBilletForm.resetValidation();
            } else {
                console.log('open1')
                if (this.editContractData && this.editContractData.payment_condition) {
                    this.formData = { ...this.editContractData.payment_condition }
                }
            }
        }
    },
    data() {
        return {
            profilesCbos: [],
            banksProfileRegisterCbo: [],
            registerProfileDilog: false,
            billingsType: [
                { id: 1, name: "valor fixo" },
                { id: 2, name: "Percentual" }
            ],
            formData: { ...resetBillingBilletForm },
            valid: true,
        }
    },
    methods: {
        resetValidationField() {
            console.log('aqui')
            setTimeout(() => {
                this.$refs.showHint.resetValidation()
            }, 2500);
        },
        getProfiles() {
            var obj = {
                url: "/api/v1/title/user/profile/get-all",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.profilesCbos = [
                    ...response.data.map((prf) => {
                        return { id: prf.id, name: prf.profile_name };
                    }),
                ];
                this.profiles = [...response.data];
            });
        },
        toogleRegisterProfileDilog() {
            this.registerProfileDilog = !this.registerProfileDilog
        },
        getConfiguredBanks(loader) {
            var obj = {
                // noLoader: loader,
                url: "/api/v1/title/user/bank/getUserBank",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.banksProfileRegisterCbo = [
                    ...response.data.map((obj) => {
                        return {
                            number_bank: obj.bank.number_bank,
                            pg_bank_favoured_id: obj.id,
                            bank_name: obj.bank.bank_name,
                        };
                    }),
                ];
                //this.banks = [...response.data];
            });
        },
    },
    props: {
        contractDialog: Boolean,
        editContractData: Object,
        paymentConditionOpen: Function
    },
    computed: {
        due_dateFormated: {
            get: function () {
                let date = this.formData.due_date
                    ? moment(this.formData.due_date).format("DD/MM/YYYY")
                    : "";
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                let date = moment(this.formData.due_date).format("DD/MM/YYYY");
                // console.log("aaaa", newValue);
                if (date == "Invalid date") {
                    return null;
                }
                if (!newValue) {
                    this.formData.due_date = newValue;
                }
                return newValue;
            },
        },
    },
    components: {
        //ProfileRegister,
    },
}
</script>

<style></style>