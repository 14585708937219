<template>
  <v-form class="mt-15" ref="contractForm" v-model="valid">
    <v-row>
      <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
        <v-subheader class="pl-0 mb-5">
          <h3>Escolha um serviço para contratar</h3>
        </v-subheader>
        <v-select :disabled="editContractData && editContractData.id ? true : false" @change="getServices"
          :rules="$store.state.formRules.required" v-model="formData.company_id" :items="companies" item-text="name"
          item-value="id" label="Empresas" single-line>
        </v-select>
        <v-select @change="getTemplates" :disabled="setDisabled('service', formData.company_id)"
          :rules="$store.state.formRules.required" clearable v-model="formData.service_id" :items="services"
          item-text="name" item-value="service_id" label="Serviços">
        </v-select>
        <v-select v-if="verifyServicePrefix(formData.service_id)" clearable v-model="formData.bank" :items="banksList"
          :rules="[(v) => (v.length ? true : false || 'Campo obrigatório')]" item-text="bank_name"
          item-value="number_bank" attach small-chips label="Selecione os bancos" multiple>
        </v-select>
        <div class="infoText pt-2">
          <h6 class="subtitle-2 grey--text text--darken-1">
            Sobre os serviços disponíveis
          </h6>
          <p class="caption mb-2 grey--text text--darken-1">
            Hoje temos algumas opções de serviços de STREAMING para serem
            contratadas.
          </p>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
        <v-subheader class="pl-0 mb-5">
          <h3>Escolha o tipo do contrato</h3>
        </v-subheader>
        <v-select :disabled="setDisabled('template', formData.service_id)" :rules="$store.state.formRules.required"
          item-text="name" item-value="id" clearable tex v-model="formData.contracts_template_id" :items="templates"
          label="Tipo de contrato"></v-select>

        <v-checkbox :disabled="setDisabled('template', formData.service_id)" v-model="formData.certificadoicpbr"
          label="Assinar com certificado digital"></v-checkbox>

        <div class="infoText pt-2">
          <h6 class="subtitle-2 grey--text text--darken-1">
            Assinatura com certificado digital
          </h6>
          <p class="caption grey--text text--darken-1">
            Selecione a opção acima caso o contrato atual possa ser assinado
            utilizando um certificado digital.
          </p>
        </div>
      </v-col>
      <v-divider vertical style="min-height: 400px"></v-divider>
      <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
        <v-subheader class="pl-0 mb-5">
          <h3>Selecione um provedor para o contrato</h3>
        </v-subheader>
        <!-- :hint="paymentCondition ? 'Selecione acima o provedor responsável por este contrato.' : ''" -->
        <v-autocomplete :disabled="editContractData && editContractData.id ? true : false" item-text="name"
          item-value="id" clearable tex v-model="formData.partner_id" :items="provaiders" label="Provedor"
          :rules="$store.state.formRules.required" auto-select-first></v-autocomplete>
        <div v-if="!paymentCondition" class="infoText pt-2">
          <h6 class="subtitle-2 grey--text text--darken-1">
            Provedores / clientes
          </h6>
          <p class="caption grey--text text--darken-1">
            Selecione acima o provedor responsável por este contrato.
          </p>
        </div>

        <v-checkbox :disabled="disableIsPartner" v-if="paymentCondition" class="mt-5 pt-0" v-model="formData.in_abranet"
          label="Utilizar gerenciamento de subscrições"></v-checkbox>
        <!-- :disabled="setDisabled('template', formData.service_id)" -->
        <div v-if="paymentCondition" :class="['infoText pt-0', disableIsPartner ? 'disableText' : '']">
          <h6 class="subtitle-2 grey--text text--darken-1">
            Gerenciamento de subscrições
          </h6>
          <p class="caption grey--text text--darken-1">
            Selecione a opção acima caso quera gerar o contrato através de uma empresa que faça o gerenciamento das
            subscrições deste contrato.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const restContractForm = {
  id: "",
  service_id: "",
  company_id: "",
  contracts_template_id: "",
  partner_id: "",
  certificadoicpbr: false,
  in_abranet: false,
  bank: [],
};
export default {
  watch: {
    contractDialog(v) {
      if (!v) {
        this.formData = { ...restContractForm };
        this.disableIsPartner = false
        this.$refs.contractForm.resetValidation();
      } else {
        if (this.editContractData) {
          this.formData = { ...this.editContractData };
          this.getServices();
          this.getTemplates();
        }
      }
    },
  },
  props: {
    contractDialog: Boolean,
    editContractData: Object,
    paymentConditionOpen: Function,
    paymentCondition: Boolean,
    getPaymentsData: Function
  },
  mounted() {
    this.getCompanyies();
    this.getProviders();

    //this.getServices();
    //console.log("mounted");
    if (this.editContractData) {
      this.formData = { ...this.editContractData };
      this.getServices();
      this.getTemplates();
    }
  },
  methods: {
    verifyServicePrefix(idService) {
      console.log("showbank");
      let showBanks = false;
      this.services.map((serv) => {
        if (serv.service_id == idService && serv.prefix == "pagamento") {
          showBanks = true;
        }
      });
      return showBanks;
    },
    getBanks() {
      var obj = {
        url: "/api/title/bank/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.banksList = response.data;
      });
    },
    setDisabled(type, id) {
      if (this.formData && this.formData.id) {
        return true;
      }
      if (type == "service") {
        if (id) {
          return false;
        } else {
          return true;
        }
      }
      if (type == "template") {
        if (id) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    getCompanyies() {
      var obj = {
        url: "/api/company/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.companies = [...response.data];
      });
    },
    getProviders() {
      var obj = {
        url: "/api/partner/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.provaiders = [...response.data];
      });
    },
    verifyPartner() {
      let id = this.formData.company_id;
      let ind = this.companies.findIndex(
        (company) => company.id === id
      );
      if (this.companies[ind].is_partner) {
        this.disableIsPartner = false
      } else {
        this.formData.in_abranet = ""
        this.disableIsPartner = true
      }

    },
    getServices(verifyPartner) {
      if (verifyPartner) {
        this.verifyPartner()
      }
      var obj = {
        url: "/api/service/getByCompany",
        query: { company_id: this.formData.company_id },
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.services = [...response.data];
        for (let i = 0; i < this.services.length; i++) {
          if (this.services[i].prefix == "pagamento") {
            this.getBanks();
            this.getPaymentsData()
          }
        }

      });
    },
    getTemplates() {

      var obj = {
        url: "/api/contract-creadentials/getByCompanyService",
        query: {
          company_id: this.formData.company_id,
          service_id: this.formData.service_id,
        },
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.templates = [...response.data];
        setTimeout(() => {
          this.paymentConditionOpen()
        }, 10);
      });
    },
  },
  data() {
    return {
      disableIsPartner: false,
      banksList: [],
      templates: [],
      companies: [],
      valid: true,
      provaiders: [],
      services: [],
      formData: { ...restContractForm },
    };
  },
};
</script>

<style>
.disableText {
  opacity: 0.5;
}
</style>