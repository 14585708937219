<template>
  <v-card class="mx-4" width="344" color="blue-grey lighten-5">
    <v-card-text>
      <p class="text-h6 text--primary">{{ dataCard.company.name }}</p>
      <v-alert
        class="my-2 py-1 pl-1 caption fromatAlert font-weight-medium"
        dense
        text
        :type="contractStatus(dataCard.status, 0)"
        >{{ contractStatus(dataCard.status, 1) }}</v-alert
      >
      <v-list-item-subtitle class="mt-2 ml-1"
        >◉ Adesão -
        <b>{{ dataCard.accession_date | formatDate }}</b></v-list-item-subtitle
      >
    </v-card-text>
    <v-divider class="mb-1"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="downloadPdf(dataCard.id)"
            class="mr-2"
            icon
            color="blue-grey darken-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>picture_as_pdf</v-icon>
          </v-btn>
        </template>
        <span>Download do contrato</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import MyFilters from "../../filters/genericFilters";
export default {
  props: { dataCard: Object },
  methods: {
    downloadPdf(id) {
      let obj = {
        url: "/api/contract/getContractPdf",
        query: { contract_id: id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        console.log(response.data);
        window.open(response.data.url, "_blank");
      });
    },
    contractStatus(status, returning) {
      //  == 0 ? 'error' : 'success'
      //  == 0 ? "CONTRATO CANCELADO" : "CONTRATO ATIVO"
      let setStatus;
      let setText;
      switch (status) {
        case "signed":
          setStatus = "success";
          setText = "CONTRATO ASSINADO";
          break;

        case "awaiting_signature":
          setStatus = "warning";
          setText = "AGUARDANDO ASSINATURA";
          break;

        default:
          setStatus = "error";
          setText = "CONTRATO CANCELADO";
          break;
      }
      if (returning) {
        return setText;
      } else {
        return setStatus;
      }
    },
  },
};
</script>

<style>
</style>