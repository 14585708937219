<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <!-- <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >Contratos cadastrados</v-card-title
      > -->

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleContractDialog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Contrato</span>
      </v-tooltip>
      <!-- <div v-if="responsiveSize >= 500" align="end" class="filterAbsolutAdm">
        <v-tooltip left content-class="tooltipFilter">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on">
              <v-select
                item-text="name"
                item-value="value"
                clearable
                tex
                open-on-clear
                v-model="contractType"
                :items="[
                  { name: 'Todos os contratos', value: 'all' },
                  { name: 'Contratos Pós pagos', value: 'Pos pago' },
                  { name: 'Contratos Pré pagos', value: 'Pre pago' },
                ]"
                label="Tipo de contrato"
                hide-details
                prepend-icon="filter_alt"
                single-line
                @change="filterContracts"
              ></v-select>
            </v-col>
          </template>
          <span class="">Filtrar por tipo de contrato</span>
        </v-tooltip>
      </div> -->

      <v-row align="center" class="px-13 pb-5 mt-16" v-if="!contracts.length">
        <v-spacer></v-spacer>
        <!-- <v-alert border="top" colored-border type="info" elevation="2">
          Você ainda não possui contratos cadastrados. Clique no botão
          "Adicionar Contrato" localizado no canto superior da tela e crie o seu
          primeiro contrato.
        </v-alert> -->
        <v-alert
          class="mt-16 pb-3"
          text
          dense
          color="grey"
          icon="mdi-clock-fast"
          border="bottom"
        >
          Você ainda não possui contratos cadastrados. Clique no botão
          "Adicionar Contrato" localizado no canto superior da tela e crie o seu
          primeiro contrato.
        </v-alert>
        <v-spacer></v-spacer>
      </v-row>
      <v-card-title class="ml-15 py-8 text-h5 pl-0 black--text"
        >◎ Contratos iniciais</v-card-title
      >
      <v-row
        align="start"
        class="px-0 pb-5 mx-auto"
        @mouseover="showArrowsHover(true)"
        @mouseout="showArrowsHover(false)"
      >
        <v-slide-group
          v-model="slide"
          class="contractSlide"
          :show-arrows="showArrows"
        >
          <v-slide-item
            v-for="(contract, i) in initContracts"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <!-- <v-col cols="12" xs="12"> -->
            <InitCardContract
              :input-value="active"
              @click="toggle"
              :dataCard="contract"
              depressed
            />
            <!-- </v-col> -->
          </v-slide-item>
        </v-slide-group>
      </v-row>
      <v-divider class="mb-1 mt-3"></v-divider>
      <v-card-title class="ml-15 py-8 text-h5 pl-0 black--text"
        >◎ Contratos de venda</v-card-title
      >
      <v-row align="start" class="px-13 pb-5">
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="(contract, i) in contracts"
          :key="i"
        >
          <CardContract
            :dataCard="contract"
            :editContract="editContract"
            :confirmCancelContract="confirmCancelContract"
          />
        </v-col>
      </v-row>
    </v-card>
    <ContractDialog
      :getContracts="getContracts"
      :toogleContractDialog="toogleContractDialog"
      :contractDialog="contractDialog"
      :editContractData="editContractData"
      :bankSlipData="bankSlipData"
      :updateBankSlipData="updateBankSlipData"
      :qrCodeData="qrCodeData"
      :getQrCode="getQrCode"
    />
  </div>
</template>

<script>
import CardContract from "./CardContract";
import ContractDialog from "./ContractDialog";
import InitCardContract from "./InitCardContract";

export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  components: {
    CardContract,
    ContractDialog,
    InitCardContract,
  },
  beforeMount() {
    this.getHeightPage();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  methods: {
    showArrowsHover(status) {
      this.showArrows = status;
    },
    confirmCancelContract(contract) {
      let contractIndex = this.contracts.indexOf(contract);
      let obj = {
        message: `Você deseja cancelar o contrato <b>${contract.service.description}</b>?`,
        title: "Cancelar Contrato",
        callback: (_) => {
          this.delContract(contract);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delContract(data) {
      let obj = {
        url: "/api/contract/cancelContract",
        query: { contract_id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) return;
        let ind = this.contracts.findIndex(
          (contract) => contract.id == data.id
        );
        this.getContracts();
        let opts = {
          message: `Contrato <b>${data.service.description}</b> cancelado com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    filterContracts() {
      if (this.contractType) {
        this.getContracts();
      }
      //console.log(this.contractType);
    },
    toogleContractDialog() {
      //console.log("toogle");
      this.editContractData = null;
      this.contractDialog = !this.contractDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    editContract(id) {
      var obj = {
        url: "/api/contract/getById",
        query: { id: id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (response.data.bank_slips_transaction.length) {
          this.updateBankSlipData(response.data.bank_slips_transaction[0]);
        }
        if (response.data.pix_transaction.length) {
          this.getQrCode(response.data.pix_transaction[0].id);
        }
        this.editContractData = { ...response.data };
        this.contractDialog = true;
      });
    },
    getQrCode(id) {
      var obj = {
        url: "/api/pix/qrcode/generate",
        query: { id: id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response);
        this.qrCodeData = {
          qrCodeImg: `data:image/png;base64,${response.data.image}`,
          payload: response.data.payload,
        };
      });
    },
    updateBankSlipData(data) {
      this.bankSlipData = {
        ...JSON.parse(data.transaction_data),
      };
      this.bankSlipData.transaction_date = data.transaction_date;
      this.bankSlipData.value = data.value;
    },
    getContracts(nLoad) {
      var obj = {
        noLoader: nLoad == "nLoad" ? true : false,
        url: "/api/contract/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.contracts = [
          ...response.data.filter(
            (contract) => contract.contract_type != "Company contract"
          ),
        ];
        this.initContracts = [
          ...response.data.filter(
            (contract) => contract.contract_type == "Company contract"
          ),
        ];
      });
    },
  },
  mounted() {
    this.getContracts();
  },
  data() {
    return {
      windowH: "580",
      showArrows: false,
      slide: null,
      qrCodeData: null,
      bankSlipData: null,
      editContractData: null,
      contractDialog: false,
      contracts: [],
      initContracts: [],
      contractType: "",
      labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
      time: 0,
      forecast: [
        {
          day: "Tuesday",
          icon: "mdi-white-balance-sunny",
          temp: "24\xB0/12\xB0",
        },
        {
          day: "Wednesday",
          icon: "mdi-white-balance-sunny",
          temp: "22\xB0/14\xB0",
        },
        { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
      ],
    };
  },
};
</script>

<style>
.contractSlide .v-slide-group__wrapper {
  padding-bottom: 3px !important;
}
</style>