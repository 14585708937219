<template>
  <v-card
    class="
      mx-auto
      myPopover
      rounded-bl-0 rounded-br-lg rounded-tr-lg rounded-tl-lg
    "
    max-width="350"
    elevation="1"
  >
    <v-card-title primary-title class="pl-5 pr-2 py-1 primary--text">
      <v-icon class="mr-2 primary--text">contact_support</v-icon>
      {{ title }} <v-spacer></v-spacer
      ><v-btn @click="closeInfo" color="error" icon x-small class="mb-2"
        ><v-icon style="font-size: 15px">close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text class="pl-6 pr-5 py-2"> {{ details }} </v-card-text>
    <!-- <v-card-actions>
      <v-btn outlined rounded text> Button </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  mounted() {
    this.setPosition();
  },
  watch: {
    infoShow(v) {
      if (v) {
        this.setPosition();
      }
    },
  },
  methods: {
    setPosition() {
      //console.log("pos");
      let x = this.left;
      let y = this.top;
      let box = document.getElementsByClassName("myPopover")[0];
      box.style.top = `${y}px`;
      box.style.left = `${x}px`;
    },
  },

  props: {
    title: String,
    details: String,
    infoShow: Boolean,
    closeInfo: Function,
    top: String,
    left: String,
  },
};
</script>

<style>
.myPopover {
  position: absolute;
  z-index: 99999;
}
.myPopover .v-card__title {
  background: whitesmoke;
}
</style>