<template>
  <v-card class="mx-auto bgContract" max-width="320" tile>
    <v-list-item three-line>
      <v-list-item-content>
        <div>
          <v-img class="mb-4" v-if="dataCard.service.prefix == 'Disney+'" contain max-width="85" min-height="45px"
            position="top center" src="../../assets/disney.png"></v-img>
          <v-img class="mt-4 mb-0" v-if="dataCard.service.prefix == 'dtvgo'" contain min-height="45px" max-width="150"
            position="top center" src="../../assets/directvgo.svg"></v-img>
          <v-img class="mb-2" v-if="dataCard.service.prefix == 'netflix'" contain min-height="45px" max-width="100"
            position="top center" src="../../assets/netflix.png"></v-img>
          <v-img class="mb-2" contain max-width="100" min-height="45px" v-if="dataCard.service.prefix == 'primeVideo'"
            position="top center" src="../../assets/prime-video.png"></v-img>
          <!-- <v-img
          contain
          position="top center"
          src="../../../assets/disney.png"
        ></v-img> -->
        </div>
        <!-- <div class="text-overline blue-grey--text text--darken-2 mb-0">Contrato</div> -->
        <!-- <v-list-item-title class="text-h6 mb-2 mt-1">{{
          dataCard.contract_type
        }}</v-list-item-title> -->
        <v-list-item-title class="subtitle-2 mb-1 blue-grey--text text--darken-2">{{ dataCard.company.name
        }}</v-list-item-title>
        <v-list-item-subtitle class="mt-2">{{ dataCard.service.description }}<br />
          <span class="font-weight-regular caption">CONTRATO: </span>
          <span class="caption font-weight-medium">{{
            dataCard.contract_type.toUpperCase()
          }}</span></v-list-item-subtitle>

        <v-list-item-subtitle class="my-2 font-weight-medium"><span class="font-weight-regular">Cliente:</span>
          {{ dataCard.partner.name }}</v-list-item-subtitle>

        <v-alert class="my-2 py-1 pl-1 caption fromatAlert font-weight-medium" dense text
          :type="contractStatus(dataCard.status, 0)">{{ contractStatus(dataCard.status, 1) }}</v-alert>
        <v-list-item-subtitle class="mt-2 ml-1">◉ Adesão -
          <b>{{
            dataCard.accession_date | formatDate
          }}</b></v-list-item-subtitle>
        <v-list-item-subtitle class="mt-2 ml-1">◉ Vencimento -
          <b>{{ dataCard.validate_date | formatDate }}</b></v-list-item-subtitle>
        <v-list-item-title class="subtitle-2 mb-1 mt-4 blue-grey--text text--darken-2 ml-1">{{ dataCard.recurrence |
          recurrenceText }}</v-list-item-title>
        <v-list-item-subtitle class="mt-2 pl-1">Valor do contrato</v-list-item-subtitle>
        <v-list-item-title class="pl-1 text-h6 mb-1 green--text text--darken-2">R$ {{ dataCard.value | formatPrice
        }}</v-list-item-title>
      </v-list-item-content>
      <!-- <v-list-item-avatar class="mr-1" tile size="150" color="transparent">
        
      </v-list-item-avatar> -->
    </v-list-item>
    <v-divider class="mb-1"></v-divider>
    <v-card-actions>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="downloadPdf(dataCard.id)" icon color="blue-grey darken-1" v-bind="attrs" v-on="on">
            <v-icon>picture_as_pdf</v-icon>
          </v-btn>
        </template>
        <span>Download do contrato</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="confirmCancelContract(dataCard)" icon color="blue-grey darken-1" v-bind="attrs" v-on="on">
            <v-icon>block</v-icon>
          </v-btn>
        </template>
        <span>Cancelar contrato</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="editContract(dataCard.id)" icon color="blue-grey darken-1" v-bind="attrs" v-on="on">
            <v-icon>{{ dataCard.payment_condition ? 'edit' : 'edit' }}</v-icon>
          </v-btn>
        </template>
        <span>Visualizar / Editar contrato</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import MyFilters from "../../filters/genericFilters";
export default {
  methods: {
    downloadPdf(id) {
      let obj = {
        url: "/api/contract/getContractPdf",
        query: { contract_id: id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response.data.url);
        window.open(response.data.url, '_blank');
      });
    },
    contractStatus(status, returning) {
      //  == 0 ? 'error' : 'success'
      //  == 0 ? "CONTRATO CANCELADO" : "CONTRATO ATIVO"
      let setStatus;
      let setText;
      switch (status) {
        case "signed":
          setStatus = "success";
          setText = "CONTRATO ASSINADO";
          break;

        case "awaiting_signature":
          setStatus = "warning";
          setText = "AGUARDANDO ASSINATURA";
          break;

        default:
          setStatus = "error";
          setText = "CONTRATO CANCELADO";
          break;
      }
      if (returning) {
        return setText;
      } else {
        return setStatus;
      }
    },
  },
  filters: {
    recurrenceText(v) {
      let recurrences = [
        "Sem recorrência",
        "Recorrência mensal",
        "Recorrência bimestral",
        "Recorrência trimestral",
        "Recorrência semestral",
        "Recorrência anual",
      ];
      return recurrences[v];
    },
  },
  props: {
    dataCard: Object,
    editContract: Function,
    confirmCancelContract: Function,
  },
};
</script>

<style>
.bgContract {
  /* background-color: rgba(200, 200, 200, 0.5) !important; */
}

.fromatAlert i {
  margin-right: 5px !important;
}
</style>